export const fr = {
  password: "mot de passe",
  search: "Recherche",
  searchJobDocument: "N° de doc.",
  painel: "Panneau",
  "tela de janela": "Moustiquaire",
  "porta do trailer": "Porte de remorque",
  "modo espera": "Statut Fleetmate",

  "inicio viagem": "Début du voyage",
  "fim de viagem": "Fin du voyage",
  "Ao Entrar na Cerca Eletrô...": "En entrant dans la clôture électronique...",
  "Ao Sair da Cerca Eletrô...": "En quittant la clôture électronique...",
  export: "Exporter",
  send: "Envoyer",
  Carreta: "Chariot",
  Carro: "Voiture",
  Moto: "Moto",
  Toco: "Toco",
  Truck: "Camion",
  Utilitario: "Utilitaire",
  Van: "Van",
  VUC: "VCU",
  Base: "Base",
  Cliente: "Client",
  no_equipment: "Aucun équipement",
  "aside.tracking": "Surveillance",
  "aside.routing": "Script",
  "aside.vehicles": "Véhicules",
  "aside.drivers": "Conducteurs",
  "aside.journey": "Journée des conducteurs",
  "aside.trips": "Voyages",
  "aside.sensors": "Capteurs",
  "aside.fences": "Clôtures électroniques",
  "aside.alerts": "Alertes",
  "sidebar.change_company": "Changer de société",
  "sidebar.search": "Chercher...",
  not_found: "Rien n'a été trouvé",
  "company.success": "J'ai changé d'entreprise",
  "Área de risco": "Zone à risque",
  "Ponto de parada autorizado": "Point d'arrêt autorisé",
  Rota: "Itinéraire",
  FLUSH_DOOR_MAIN: "Porte de coffre secondaire",
  "tracking.modal_move_confirm":
    "Vous confirmez le changement de tâche au véhicule",
  "tracking.modal_move_confirm_title":
    "Confirmez-vous le changement de tâche en orpheline ?",
  "bloqueio de combustível": "Serrure à carburant",
  "trava de baú principal": "Serrure du coffre principal",
  "trava de baú lateral": "Serrure de coffre latérale",
  "trava da quinta roda": "Verrouillage de la sellette d'attelage",
  "trava do trailer": "Serrure de remorque",
  sirene: "Sirène",
  engate: "Attelage",
  "porta do motorista": "Porte conducteur",
  "porta baú lateral": "Porte de coffre latérale",
  "porta baú principal": "Porte du coffre principal",
  "porta do carona": "Porte passager",
  ignição: "Allumage",
  silo: "Silo",
  "quinta roda": "Cinquième roue",
  pânico: "Panique",
  electronic_fences: "Clôture électronique",
  "month.January": "Janvier",
  "month.February": "Février",
  "month.March": "Mars",
  "month.April": "Avril",
  "month.May": "Mai",
  "month.June": "Juin",
  "month.July": "Juillet",
  "month.August": "Août",
  "month.September": "Septembre",
  "month.October": "Octobre",
  "month.November": "Novembre",
  "month.December": "Décembre",
  "button.save": "Enregistrer",
  "button.delete": "Supprimer",
  "button.cancel": "Annuler",
  "button.confirm": "Confirmer",
  "confirm.message": "Confirmation",
  "text.yes": "Oui",
  "text.no": "Non",
  "uploadbox.sub_title_link": "Télécharger",
  "uploadbox.sub_title":
    "exemple pour envoyer des tâches en xls ou envoyer un fichier au format zip contenant les xml des NFe.",
  "uploadbox.success.message": "Les tâches ont été traitées avec succès",
  "uploadbox.message": "Traitement",
  "text.edit": "Modifier",
  "text.delete": "Supprimer",
  "status.saving": "Enregistrement... ",
  "status.sending": "Envoi... ",
  "status.waiting": "Attendez... ",
  "input.select_text": "Sélectionner",
  "link.go_back": "Retour",
  "text.filter": "Filtrer",
  "text.map_view": "Voir sur la carte",
  "text.route_view": "Voir la route",
  "text.required": "(Requis)",
  "placeholde.select": "Choisir véhicule...",
  "component.tag_label": "Attribut (TAG)",
  "message.confirm_delete_many_registers":
    "Êtes-vous sûr de vouloir supprimer les enregistrements sélectionnés?",
  "message.confirm_delete_register":
    "Êtes-vous sûr de vouloir supprimer cet enregistrement?",
  selecteds: "Sélectionné",

  "send.router": "Êtes-vous sûr de vouloir envoyer ce routage pour contrôle ?",

  "login.welcome_message": "Content de te revoir!",
  "login.subtitle": "Entrez vos coordonnées pour accéder au système",
  "login.password_label": "Mot de passe",
  "login.email_placeholder": "Tapez votre e-mail",
  "login.password_placeholder": "Tapez votre mot de passe",
  "login.remember_password": "Mémoriser mon mot de passe",
  "login.recover_password": "Récupérer mot de passe",

  "login.slide_first_title": "Température et humidité de la poitrine",
  "login.slide_first_subtitle":
    "Contrôlez la température et l'humidité du coffre à l'aide d'enregistreurs de données Bluetooth dotés d'une batterie interne pouvant aller jusqu'à 5 ans.",
  "login.slide_second_title": "Comportement de conduite",
  "login.slide_second_subtitle":
    "Évitez les accidents et réduisez les coûts d’exploitation de votre flotte. Contrôlez les événements impliquant des excès de vitesse, l’utilisation du téléphone portable, le freinage, l’accélération et les virages brusques.",
  "login.slide_fourth_title": "Routage cloud",
  "login.slide_fourth_subtitle":
    "Créez des itinéraires optimisés pour votre flotte en tenant compte de nombreuses restrictions : telles que la fenêtre de service, le parcours du conducteur, les attributs du véhicule, le volume, le poids et la valeur.",

  "forgot_password.back": "Retourner",
  "forgot_password.title": "Mot de passe oublié?",
  "forgot_password.subtitle":
    "Entrez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
  "forgot_password.second_subtitle":
    "Pour des raisons de sécurité, nous ne stockons PAS votre mot de passe. Soyez donc assuré que nous n’enverrons jamais votre mot de passe par e-mail.",
  "forgot_password.email_label": "Adresse e-mail",
  "forgot_password.placeholder": "Tapez votre e-mail",
  "forgot_password.button_message": "Envoyer les instructions de configuration",
  "forgot_password.success_message":
    "Un message a été envoyé à votre adresse e-mail.",

  "reset_password.title": "Redéfinir le mot de passe",
  "reset_password.subtitle": "Crée un nouveau mot de passe",
  "reset_password.new_password": "nouveau mot de passe",
  "reset_password.new_password_placeholder": "entrer un nouveau mot de passe",
  "reset_password.repeat_password": "Confirmation du nouveau mot de passe)",
  "reset_password.repeat_password_placeholder":
    "Répéter le mot de passe précédent",
  "reset_password.repeat_password_button":
    "Envoyer Soumettre Votre mot de passe a été modifié avec succès !",
  "reset_password.repeat_password_error_message": "Jeton invalide",
  "reset_password.back_to_login": "Retour connexion",

  "drivers.add": "Ajouter des conducteurs",
  "drivers.title": "Conducteurs",
  "drivers.upload_title":
    "Faites glisser ici pour importer plusieurs conducteurs",
  "drivers.tasks_title": "Total des tâches de routage en attente",
  "drivers.tasks_button": "Voir les tâches",
  "drivers.table_title": "Conducteurs autorisés",
  "drivers.table_name": "NOM DU CONDUCTEUR",
  "drivers.table_document": "DOCUMENT",
  "drivers.table_email": "E-MAIL",
  "drivers.table_phone": "TÉLÉPHONE",
  "drivers.table_authorized": "AUTORISÉ",
  "drivers.table_responsible": "UTILISATEUR RESPONSABLE",
  "drivers.delete_selected_registers":
    "Supprimer les enregistrements sélectionnés",
  "drivers.delete_many_registers.message":
    "Êtes-vous sûr de vouloir supprimer les conducteurs sélectionnés?",
  "drivers.delete_registers.message":
    "Êtes-vous sûr de vouloir supprimer ce conducteur?",
  "drivers.add_upload_label":
    "Ajoutez l'e-mail, le téléphone ou le numéro CPF des conducteurs.",
  "drivers.add_upload_subtitle":
    "Les données doivent être séparées par une virgule ou un point-virgule.",
  "drivers.add_upload_enter": "Copier et coller ici",
  "drivers.add_button": "Ajouter",
  "drivers.cancel_button": "Annuler",
  "drivers.add_driver_text_confirm":
    "Veuillez vérifier les enregistrements et confirmer l'ajout des conducteurs",
  "drivers.add_register_found": "enregistrements trouvés",
  "drivers.success_title": "conducteurs ajoutés avec succès!",
  "drivers.add.message_error": "Échec de l'ajout des conducteurs",
  "drivers.delete_many_success_title": "conducteurs supprimés avec succès!",
  "drivers.delete_success_title": "Conducteur supprimé avec succès!",
  "drivers.delete_many.message_error":
    "Échec de la suppression des conducteurs sélectionnés",
  "drivers.delete.message_error": "Échec de la suppression du conducteur",
  "drivers.empty_list.message":
    "Vous n'avez pas encore de conducteurs autorisés",

  "routing.add": "Créer une nouvelle tâche manuellement",
  "routing.title": "Routage",
  "routing.upload_title": "Charger les tâches",
  "routing.tasks_title": "Tâches de routage en attente",
  "routing.tasks_button": "Sélectionner et router",
  "routing.table_title": "Routages effectués",
  "routing.table_date": "Date de création",
  "routing.table_id": "ID",
  "routing.table_status": "STATUT",
  "routing.table_qtd_tasks": "TÂCHES",
  "routing.table_qtd_routes": "ROUTES",
  "routing.table_total_distance": "Km total",
  "routing.table_user": "UTILISATEUR",
  "routing.tasks_view_title": "Voir les tâches",
  "routing.selected_tasks": "Tâches de routage sélectionnées",
  "routing.routing_start_button": "Démarrer le routage",
  "routing.table_task_id": "ID de la tâche",
  "routing.table_customer": "Client",
  "routing.table_address": "Adresse",
  "routing.table_type": "Type",
  "routing.table_weight": "Poids",
  "routing.table_volume": "Volume",
  "routing.table_value": "Valeur",
  "routing.table_document_label": "Numéro de document",

  "routing.divergence_error.type_service_required":
    "Type de prestation obligatoire – Il est nécessaire de renseigner le type de prestation.",
  "routing.divergence_error.tag_not_found":
    "Tag not found – Le tag demandé n'a pas été trouvé.",
  "routing.divergence_error.valor_required":
    "Valeur requise – Il est nécessaire de saisir une valeur.",
  "routing.divergence_error.valor_required_numeric":
    "Valeur numérique obligatoire – La valeur saisie doit être numérique.",
  "routing.divergence_error.value_required_greater_than_or_equal_to_0":
    "La valeur doit être supérieure ou égale à 0 – La valeur saisie doit être supérieure ou égale à zéro.",
  "routing.divergence_error.volume_required":
    "Volume obligatoire – Il est nécessaire de saisir un volume.",
  "routing.divergence_error.volume_required_numeric":
    "Volume numérique obligatoire – Le volume saisi doit être numérique.",
  "routing.divergence_error.volume_required_greater_than_or_equal_to_0":
    "Le volume doit être supérieur ou égal à 0 – Le volume déclaré doit être supérieur ou égal à zéro.",
  "routing.divergence_error.peso_required":
    "Poids obligatoire – Il est nécessaire de saisir un poids.",
  "routing.divergence_error.peso_required_numeric":
    "Poids numérique obligatoire – Le poids saisi doit être numérique.",
  "routing.divergence_error.peso_required_greater_than_or_equal_to_0":
    "Le poids doit être supérieur ou égal à 0 – Le poids saisi doit être supérieur ou égal à zéro.",
  "routing.divergence_error.address_required":
    "Adresse requise – Une adresse doit être fournie.",
  "routing.divergence_error.data_jurney_final_required":
    "Date de fin du voyage obligatoire – Il est nécessaire de renseigner la date de fin du voyage.",
  "routing.divergence_error.data_jurney_start_required":
    "Date de début du voyage obligatoire – Il est nécessaire de renseigner la date de début du voyage.",
  "routing.divergence_error.hour_jurney_final_required":
    "Heure de fin du trajet obligatoire – Il est nécessaire de renseigner l'heure de fin du trajet.",
  "routing.divergence_error.hour_jurney_start_required":
    "Heure de début du trajet obligatoire – Il est nécessaire de renseigner l'heure de début du trajet.",
  "routing.divergence_error.latitude_invalid":
    "Latitude invalide – La latitude saisie n'est pas valide.",
  "routing.divergence_error.longitude_invalid":
    "Longitude invalide – La longitude saisie n'est pas valide.",
  "routing.divergence_error.date_jurney_final_pickup_required":
    "Date de fin de collecte obligatoire – Il est nécessaire de renseigner la date de fin de collecte.",
  "routing.divergence_error.date_jurney_start_pickup_required":
    "Date de début de la collecte obligatoire – Il est nécessaire de renseigner la date de début de la collecte.",
  "routing.divergence_error.hour_jurney_final_pickup_required":
    "Heure de fin de collecte obligatoire – Il est nécessaire de renseigner l'heure de fin de collecte.",
  "routing.divergence_error.hour_jurney_start_pickup_required":
    "Heure obligatoire de début de collecte – Il est nécessaire de renseigner l'heure de début de collecte.",
  "routing.divergence_error.address_de_retirada_required":
    "Adresse de retrait obligatoire – Vous devez saisir l'adresse de retrait.",
  "routing.divergence_error.latitude_pickup_invalid":
    "Latitude de collecte invalide – La latitude de collecte saisie n'est pas valide.",
  "routing.divergence_error.longitude_pickup_invalid":
    "Longueur de ramassage invalide – La longueur de ramassage saisie n'est pas valide.",
  "routing.inconsistent_address": "Adresse incohérente",
  "routing.send_success_title": "Routage envoyé avec succès.",
  "routing.send_success_message":
    "Suivez le traitement sur l'écran de routage initial ou cliquez ici pour consulter maintenant.",
  "routing.confirm_modal_popover":
    "Lors de la création d'une tâche pour un routage futur, elle sera disponible pour être incluse dans un nouveau routage.",
  "routing.confirm_modal_title": "Confirmer le routage",
  "routing.confirm_modal_subtitle":
    "Pour confirmer votre routage, veuillez saisir les informations ci-dessous et cliquer sur confirmer.",
  "routing.start_location_label": "Lieu de départ",
  "routing.start_location_select": "Sélectionner un lieu de sortie",
  "routing.start_label": "Début",
  "routing.end_label": "Fin",
  "routing.location_title_modal": "Adresse correcte",
  "routing.location_import_address": "Importer l'adresse",
  "routing.location_correct_address_label": "Adresse correcte",
  "routing.location_correct_address_enter": "Saisissez l'adresse correcte ici",
  "routing.until": "jusqu'à",
  "routing.filter_select_inconsistent_address_label":
    "Sélectionner uniquement les tâches avec une adresse incohérente",
  "routing.task_type_label": "Type de tâche",
  "routing.option_delivery": "Livraison",
  "routing.option_collect": "Collecte",
  "routing.option_service": "Service",
  "routing.option_collect_and_service": "Collecte + Livraison",

  "routing.delete_selected": "Supprimer les enregistrements sélectionnés",
  "routing.customer_enter": "Saisissez le nom du client ici",
  "routing.cod_customer_enter": "Entrez le code client ici",
  "routing.service_window_label": "Fenêtre de routage",
  "routing.service_time_label": "Heure de routage",
  "routing.location_label": "Emplacement",
  "routing.amount_label": "Montant",
  "routing.observation_label": "Observations",

  "routing.modal.confirm":
    "Lors de la création d'une tâche à exécution immédiate, celle-ci sera attribuée au véhicule le plus proche répondant à toutes les caractéristiques de la tâche.",
  "routing.phone_enter": "Saisissez le numéro de téléphone ici",
  "routing.document_label": "Numéro de document",
  "routing.document_enter": "Saisissez le document ici",
  "routing.collect_location_label": "Lieu de collecte",
  "routing.tags_popover":
    "Utilisez des tags pour ajouter des restrictions d'opération à la tâche. Exemple: utilisez le tag 'réfrigéré' pour que la tâche soit attribuée uniquement à un véhicule réfrigéré.",
  "routing.run_task_immediately_label": "Exécuter la tâche immédiatement",
  "routing.run_task_immediately_popover":
    "Lors de la création d'une tâche pour une exécution immédiate, elle sera attribuée au véhicule le plus proche qui répond à toutes les caractéristiques de la tâche.",
  "routing.request_prior_monitoring_validation_label":
    "Demander une validation préalable de la surveillance",
  "routing.request_prior_monitoring_validation_popover":
    "Les tâches prioritaires seront exécutées avant les autres tâches.",
  "routing.create_button_submit": "Créer",
  "routing.update_button_submit": "Mise à jour",
  "routing.quantity_label": "Capacité utilisée",
  "routing.weight_label": "Poids",
  "routing.value_label": "Valeur",
  "routing.material_label": "Matériel",
  "routing.material_type_select": "Sélectionner le type de matériau",
  "routing.form_title": "Créer une tâche manuellement",
  "routing.confirm_delete_many_registers":
    "Êtes-vous sûr de vouloir supprimer les tâches sélectionnées?",
  "routing.confirm_delete_register":
    "Êtes-vous sûr de vouloir supprimer cette tâche?",
  "routing.confirm_delete_message":
    "Il sera envoyé au module de routage, où il pourra être reprogrammé.",
  "routing.date_start_enter": "Date de début",
  "routing.date_end_enter": "Date de fin",
  "routing.mandatory.field": "Champs marqués comme ",
  "field.mandatory": " sont obligatoires",
  "routing.service_window_collect_label": "Fenêtre de service de collecte",
  "routing.edit.title": "Modifier la tâche",
  "routing.select.departure": "Lieu de départ",
  "routing.select.arrival": "Lieu d'arrivée",
  "routing.modal.startdate":
    "Lors du choix de la date de fin, la date doit être supérieure à aujourd'hui",
  "routing.modal.finaldate":
    "Lors du choix de la date de fin, la date doit être supérieure à la date de début",
  "routing.select.departure.message":
    "Pour confirmer votre itinéraire, veuillez saisir les détails ci-dessous et cliquez sur confirmer.",
  "routing.select.departure.dropdown": "Sélectionnez un emplacement de sortie",
  "message.success.start_route":
    "Script envoyé avec succès. Suivez le traitement sur l'écran de routage initial.",

  "routing.apartment_label": "Appartement",
  "routing.apartment_pickup_label": "Prise en charge à l'appartement",
  "routing.phone_label": "Téléphone",
  "routing.phone_pickup_label": "Prise de téléphone",
  "routing.customer_label": "Client",
  "routing.customer_pickup_label": "Ramassage client",

  "routing.footer_amount_routes": "Nombre d'itinéraires",
  "routing.footer_amount_jobs": "Nombre de tâches",
  "routing.footer_amount_meters": "Total des Kilomètresl",
  "routing.footer_amount_amount": "Total Value",
  "routing.footer_amount_weight": "Valeur Totale",
  "routing.footer_amount_km": "Volume total",

  "routing.map_header_Creation_Date": "Date de création",
  "routing.map_header_Sending_Date": "Date d'envoi",
  "routing.map_header_Status": "Statut",
  "routing.map_header_User": "Utilisateur",

  "routing.modal.network_status": "L'état du Réseau",
  "routing.status.Roterization completed": "Routérisation terminée",
  "routing.status.Sent to monitoring": "Envoyé à la surveillance",
  "routing.status.Pending review": "En attente de révision",
  "routing.status.Roterization empty":
    "Routing without routes due to time or distance",
  "routing.status.Roterization failed - vroom":
    "Routage sans itinéraires en raison du temps ou de la distance",

  "configs.title": "Paramètres",

  "configs.company.language_title": "Paramètres de langue et de région",
  "configs.company.country": "Pays",
  "configs.company.language": "Langue",
  "configs.company.timezone": "Fuseau horaire",
  "configs.company.company_title": "Entreprise",
  "configs.company.company_name": "Nom de l'entreprise",
  "configs.company.company_name_enter": "Entrez le nom de l'entreprise",
  "configs.company.cnpj": "CNPJ",
  "configs.company.street": "Rue",
  "configs.company.street_enter": "Entrez la rue",
  "configs.company.number": "Numéro",
  "configs.company.complement": "Complément",
  "configs.company.complement_enter": "Entrez un complément",
  "configs.company.neighborhood": "Quartier",
  "configs.company.neighborhood_enter": "Entrez le quartier",
  "configs.company.state": "État",
  "configs.company.city": "Ville",
  "configs.company.zipcode": "Code postal",
  "configs.company.state_label": "État",
  "configs.company.email": "E-mail de facturation",
  "configs.company.email_enter": "Entrez votre e-mail",
  "configs.company.save": "Enregistrer",
  "configs.company.cancel": "Annuler",

  "configs.exit_location.back": "Nouvel emplacement de sortie",
  "configs.exit_location.form_location_name": "Nom de l'emplacement",
  "configs.exit_location.form_location": "Emplacement",
  "configs.exit_location.form_address": "Adresse",
  "configs.exit_location.form_coordinates": "Coordonnées",

  "configs.exit_location.title": "Emplacements de sortie",
  "configs.exit_location.add": "Nouvel emplacement de sortie",
  "configs.exit_location.search": "Rechercher",
  "configs.exit_location.table_name": "Nom",
  "configs.exit_location.table_address": "Adresse",
  "configs.exit_location.table_enabled": "Activé",
  "configs.exit_location.confirm_modal_title":
    "Êtes-vous sûr de vouloir supprimer ce point de départ ?",

  "configs.exit_location.form_placeholder_name": "Entrez le nom",
  "configs.exit_location.form_placeholder_location": "Entrez l'adresse",

  "search_box_places.coords":
    "Appuyez sur Entrée pour rechercher des coordonnées",

  // Configs Routing Tabs
  "configs.routing.routing_rule_tab": "Règles de routage",
  "configs.routing.vehicle_journey_tab": "Parcours du véhicule",
  "configs.routing.capabilities_tab": "Capacités",
  "configs.routing.types_material_tab": "Types de matériaux",

  // Configs Routing Rules
  "configs.routing.rule_title": "Règles de routage",
  "configs.routing.allow_vehicle_recharge": "Autoriser la recharge du véhicule",
  "configs.routing.grouping_deliveries_breakpoint":
    "Regroupement des livraisons au point de rupture",
  "configs.routing.create_open_routes": "Créer des itinéraires ouverts",
  "configs.routing.allow_dynamic_routing": "Autoriser le routage dynamique",
  "configs.routing.allow_multi_day_routes":
    "Autoriser les itinéraires de plusieurs jours",
  "configs.routing.optimization_standard": "Norme d'optimisation",
  "configs.routing.optimize_mileage": "Optimiser la distance",
  "configs.routing.optimize_service_time": "Optimiser le temps de service",
  "configs.routing.traffic_conditions": "Conditions de circulation",
  "configs.routing.slow": "Lent",
  "configs.routing.normal": "Normal",
  "configs.routing.fast": "Rapide",
  "configs.routing.measurement_system": "Système de mesure",
  "configs.routing.metric_system": "Système métrique (kilomètres)",
  "configs.routing.imperial_system": "Système impérial (miles)",

  // Configs Routing Vehicle journey
  "configs.routing.vehicles_journey_title": "Journée des véhicules",
  "configs.routing.start_journey": "Début du voyage",
  "configs.routing.end_journey": "Fin du voyage",
  "journey.button_disabled_add_register": "Sélectionnez un pilote",
  "configs.routing.average_service_time": "Temps de service moyen (TMA)",
  "configs.routing.maximum_tasks_per_route":
    "Nombre maximal de tâches par itinéraire",
  "configs.routing.include_lunch_break": "Inclure une pause déjeuner/repos",
  "configs.routing.first_interval": "Premier intervalle",
  "configs.routing.second_interval": "Deuxième intervalle",
  "configs.routing.third_interval": "Troisième intervalle",
  "configs.routing.start_interval": "Début de l'intervalle",
  "configs.routing.end_interval": "Fin de l'intervalle",
  "configs.routing.optional": "Optionnel",

  // Configs Routing Capability
  "configs.routing.capability_title": "Capacité du véhicule",
  "configs.routing.capability": "Capacité",
  "configs.routing.tank": "Réservoir",
  "configs.routing.enable_capability": "Activer la capacité",
  "configs.routing.difine_transported_meterial":
    "Définir le matériau transporté",
  "configs.routing.allocate_only_one_task_per_capacity":
    "Attribuer une seule tâche par capacité",

  // Configs Routing Types Material
  "configs.routing.types_material_title": "Types de matériaux",
  "configs.routing.enter_material_type": "Entrez un type de matériau",
  "configs.routing.new_material_type": "Nouveau type de matériau",
  "configs.routing.material_type.required":
    "Le type de matériau est un champ obligatoire",

  // Configs Driver App Types Occurrence
  "configs.app.types_occurrence_title": "Type d'événement",
  "configs.app.enter_occurrence_type": "Entrez un type d'événement",
  "configs.app.new_occurrence_type": "Nouveau type d'événement",
  "configs.app.type_in.required": "Entrez un nom de type d'événement",
  "configs.app.occurrence_type_modal_title":
    "Êtes-vous sûr de vouloir supprimer ce type d'événement ?",

  // Configs Driver App journey Control
  "configs.app.journey_control_enable":
    "Activer le contrôle du trajet du conducteur",
  "configs.app.journey_control_legislation":
    "Législation sur le contrôle de la journée de travail",
  "configs.app.journey_control_select": "Sélectionner...",

  // Configs Driver App Form
  "configs.app.form_title": "Nouveau formulaire",
  "configs.app.form_title_edit": "Modifier le formulaire",
  "configs.app.form_table_name": "Nom",
  "configs.app.form_table_required": "Remplissage obligatoire",
  "configs.app.form_table_stage": "Étape",
  "configs.app.form_table_enabled": "Activé",
  "configs.app.form_table_type": "Type de formulaire",

  "configs.app.form_name": "Nom du formulaire",
  "configs.app.form_required": "Rendre ce formulaire obligatoire",
  "configs.app.question_without_title": "Question sans titre",
  "configs.app.option_type_short": "Réponse courte",
  "configs.app.option_type_long": "Réponse longue",
  "configs.app.option_type_multiple": "Choix multiple",
  "configs.app.option_type_selection": "Sélection",
  "configs.app.option_type_images": "Images",
  "configs.app.response_required": "Réponse obligatoire",
  "configs.app.button_add_option": "Ajouter une option",
  "configs.app.button_new_question": "nouvelle question",
  "configs.app.button_add": "Ajouter",
  "configs.app.button_cancel": "Annuler",
  "configs.app.option_type_job": "Emploi",
  "configs.app.option_type_vehicle": "Véhicule",

  // Configs Driver App Form
  "configs.app.table_type_status": "Types d'état",
  "configs.app.table_journer_relation":
    "Lien d'état avec le contrôle du trajet",
  "configs.app.type_status.required": "Le type d'état est obligatoire",
  "configs.app.journey_entry.required": "Le suivi du trajet est obligatoire",
  "configs.app.select": "Sélectionner...",
  "configs.app.journer_select":
    "Sélectionner le lien d'état avec le contrôle du trajet...",
  "configs.app.enter_driver_status": "Entrez un état",
  "configs.app.new_driver_status": "Nouvel état",
  "configs.app.disable_auto_detection":
    "Désactiver la détection automatique des états arrêtés et en mouvement",
  "configs.app.driver_status.modal_title":
    "Êtes-vous sûr de vouloir supprimer cet état ?",
  "configs.app.driver_status.request_password":
    "Demander le mot de passe conducteur à chaque nouveau statut",

  // Configs Driver App General Settings
  "configs.app.general_settings.title": "Modules d'application",
  "configs.app.general_settings.subtitle":
    "Sélectionnez les modules qui seront activés pour vos conducteurs",
  "configs.app.general_settings.tasks": "Tâches",
  "configs.app.general_settings.alerts": "Alertes",
  "configs.app.general_settings.driver_status": "État du conducteur",
  "configs.app.general_settings.chat_messages": "Messages (Chat)",
  "configs.app.general_settings.ranking":
    "Classement (Comportement du directeur)",
  "configs.app.general_settings.peripherals": "Périphériques",
  "configs.app.general_settings.drivers_workflow":
    "Flux de travail des conducteurs",
  "configs.app.general_settings.proof_tasks":
    "Soumission de la preuve des tâches (Photo)",
  "configs.app.general_settings.shipping_required": "Expédition obligatoire",
  "configs.app.general_settings.type_occurrences":
    "Soumission du type d'événements",
  "configs.app.general_settings.proof_occurrences":
    "Soumission de la preuve des événements (Photo)",

  "configs.contractors.header.title": "Paramètres de l'entrepreneur",
  "configs.contractors.title": "Entrepreneurs",
  "configs.contractors.search": "Rechercher",
  "configs.contractors.table_contractor": "ENTREPRENEUR",
  "configs.contractors.table_vehicles": "VEHICULES DISPONIBLES",
  "configs.contractors.table_disponibility": "DISPONIBILITÉ",
  "configs.contractors.table_advanced_control": "CONTROLE AVANCE",
  "configs.contractors.button.edit": "Modifier les restrictions",
  "configs.contractor.confirm_modal_title":
    "Êtes-vous sûr de vouloir supprimer cet entrepreneur ?",
  "configs.contractor.confirm_modal_message":
    "Cette action ne peut pas être annulée.",
  "configs.contractors.form_restrictions_title": "Modifier les restrictions",
  "configs.contractors.form_restrictions_description":
    "Rendre uniquement disponibles les véhicules ayant les attributs (TAG) énumérés.",
  "configs.contractors.form_label_tags": "Attributs (TAG)",
  "configs.contractor.empty_list": "Vous n'avez pas encore d'entrepreneurs",

  "configs.subcontractors.title": "Sous-traitants",
  "configs.subcontractors.add": "Inviter un sous-traitant",
  "configs.subcontractors.search": "Rechercher",
  "configs.subcontractors.table_subcontractor": "SOUS-TRAITANT",
  "configs.subcontractors.table_vehicle": "VEHICULE AGGRÉGÉ",
  "configs.subcontractors.table_email": "E-MAIL",
  "configs.subcontractors.table_visibility": "VISIBLE",
  "configs.subcontractors.table_status": "ÉTAT",

  "configs.subcontractors.form_title": "Inviter un sous-traitant",
  "configs.subcontractors.form_name": "Nom",
  "configs.subcontractors.form_name_placeholder": "Entrez le nom ici...",
  "configs.subcontractors.form_email": "E-mail",
  "configs.subcontractors.form_email_placeholder": "Entrez l'e-mail ici...",
  "configs.subcontractor.empty_list":
    "Vous n'avez pas encore de sous-traitants",
  "configs.subcontractor.confirm_modal_title":
    "Voulez-vous masquer les véhicules sous-traités dans le système ?",
  "configs.subcontractor.confirm_modal_message":
    "Cette action ne peut pas être annulée.",
  "configs.subcontractor.confirm_approval_title":
    "Souhaitez-vous rendre visibles dans le système les véhicules libérés par les sous-traitants ?",
  "configs.subcontractor.successfully_invited":
    "Sous-traitant invité avec succès !",
  "configs.subcontractor.successfully_approved":
    "Véhicules rendus visibles avec succès !",
  "configs.subcontractor.successfully_disapproved":
    "Véhicules cachés avec succès !",
  "configs.subcontractor.active": "Actif",
  "configs.subcontractor.inactive": "Inactif",
  "configs.subcontractor.awaiting_approval": "En attente d'approbation",
  "configs.subcontractor.visibility": "Visibilité",
  "configs.api.title":
    "Ici, vous pouvez créer des clés API pour les environnements de production et de Sandbox.",
  "configs.api.client_id": "Client ID",
  "configs.api.client_secret": "Générer un nouveau ClientSecret",
  "configs.api.production": "Nouvelle clé API en production",
  "configs.api.sandbox": "Nouvelle clé API en Sandbox",
  "configs.api.copy": "Copier",

  "configs.menu.company": "Entreprise",
  "configs.menu.billing": "Facturation",
  "configs.menu.manage_license": "Gérer la licence",
  "configs.menu.go_to_checkout": "Aller à la caisse",
  "configs.menu.edit_contact": "Modifier le contact pour la facture",
  "configs.menu.last_name": "Nom de famille",
  "configs.menu.edit_billing_address": "Modifier l'adresse de facturation",
  "configs.menu.billing_iridium_connectivity": "Connectivité Iridium",
  "configs.menu.billing_connectivity": "LoRaWAN connectivity",
  "configs.menu.journey_control": "Contrôle du voyage",
  "configs.menu.advanced_library": "Bibliothèque CAN-BUS avancée",
  "configs.menu.video_monitoring": "Surveillance vidéo",
  "configs.menu.sensores_bluetooth": "Capteurs Bluetooth",
  "configs.menu.routing_backoffice": "Routage et Backoffice",
  "configs.menu.plan_essential": "Essentiel",
  "configs.menu.plan_advanced": "Avancé",
  "configs.menu.plan_security": "Sécurité",
  "configs.menu.plan_professional": "Professionnel",
  "configs.menu.chose_plan": "Choisir le forfait",
  "configs.menu.current_plan": "Plan Actuel",
  "configs.menu.monthly": "Mensuel",
  "configs.menu.main_features": "Caractéristiques principales",
  "configs.menu.fleet_tracking": "Suivi de la flotte",
  "configs.menu.sms_trigger": "Déclencheur SMS",
  "configs.menu.exit_locations": "Emplacements de sortie",
  "configs.menu.routing": "Options de routage",
  "configs.menu.users": "Utilisateurs",
  "configs.menu.contractors": "Entrepreneurs",
  "configs.menu.subcontractors": "Sous-traitants",
  "configs.menu.electronic_fences": "Clôtures électroniques",
  "configs.menu.travel_history": "Histoire de voyage",
  "configs.menu.integration_api": "API de Integração",
  "configs.menu.delivery_control": "Contrôle de livraison",
  "configs.menu.digital_forms": "Formulaires numériques",
  "configs.menu.security": "Sécurité (propulsé par FleetMate)",
  "configs.menu.invoice_date": "Historique des factures",
  "configs.menu.date_hour": "DATE DE FACTURE",
  "configs.menu.invoice": "Facture",
  "configs.menu.value": "Valeur",
  "configs.menu.your_plan": "Votre plan",
  "configs.menu.licences": "Licences",
  "configs.menu.by_licences": "Par licence",
  "configs.menu.by": "Par",
  "configs.menu.by_active_driver": "Par conducteur actif",
  "configs.menu.manage_licenses": "Gérer les licences",
  "configs.menu.manage_plan": "Gérer le forfait",
  "configs.menu.last_invoice": "Dernière facture",
  "configs.menu.estimate_of_the_next_invoice":
    "Estimation de la prochaine facture",
  "configs.menu.payment_method": "Mode de paiement",
  "configs.menu.invoice_contact": "Facture Contact",
  "configs.menu.billing_address": "Adresse de facturation",
  "configs.menu.status": "Statut",
  "configs.menu.empty_table": "Aucune facture trouvée",
  "configs.menu.sms": "Notifications SMS",
  "configs.menu.app": "Application pour conducteurs",
  "configs.menu.mate": "FleetMate",
  "configs.menu.api": "Intégrations API",

  "configs.menu.audit": "Audit",
  "configs.menu.audit.filter_modal.select_type": "Sélectionnez le type...",
  "configs.menu.audit.filter_modal.select_origin": "Sélectionnez l'origine...",
  "configs.menu.audit.filter_modal.select_action": "Sélectionnez l'action...",
  "configs.menu.audit.download_modal":
    "Le rapport a été généré et sera envoyé par e-mail.",

  "sensors.audit.temperature": "Capteur de température",
  "sensors.audit.speed": "Capteur de vitesse",
  "sensors.audit.fuel": "Capteur de carburant",
  "sensors.audit.battery": "Capteur de batterie",
  "sensors.audit.CO2": "Capteur de CO₂",
  "sensors.audit.camera": "Caméra",
  "sensors.audit.moisture": "Capteur d'humidité",

  "configs.user.add": "Nouvel utilisateur",
  "configs.user.edit_access_level": "Modifier le niveau d'accès",
  "configs.user.table_name": "NOM",
  "configs.user.table_email": "EMAIL",
  "configs.user.table_nivel": "NIVEAU",
  "configs.user.table_status": "STATUT",
  "configs.user.table_enabled": "ACTIVÉ",
  "configs.user.reset_password": "Réinitialiser le mot de passe",
  "configs.user.user_delete": "Supprimer l'utilisateur",
  "configs.user.user_status_active": "Actif",
  "configs.user.user_status_guest": "Invité",
  "configs.user.invite_user": "Inviter un utilisateur",
  "configs.user.name": "Nom",
  "configs.user.name_enter": "Entrer le nom",
  "configs.user.email": "Email",
  "configs.user.email_enter": "Entrer l'email",
  "configs.user.access_level": "Niveau d'accès",
  "configs.user.access_level_enter": "Sélectionner un niveau",
  "configs.user.access_level_view": "Voir",
  "configs.user.access_level_edit": "Modifier",
  "configs.user.access_level_security": "Sécurité",
  "configs.user.access_level_commands": "Commandes",
  "configs.user.access_level_delete": "Effacement",
  "configs.user.configs.user.link_tags_plates":
    "Lier les balises et les véhicules",

  "configs.sms.back": "Retour",
  "configs.sms.add": "Nouvelle notification",
  "configs.sms.table_enabled": "activé",
  "configs.sms.table_subject": "sujet",
  "configs.sms.table_time": "heure de la notification",
  "configs.sms.form_select": "Sélectionner...",
  "configs.sms.form_input_moment": "Quand le client sera-t-il notifié?",
  "configs.sms.form_label_sms_subject": "Quel sera le sujet du SMS?",
  "configs.sms.form_label_sms_content": "Quel sera le contenu du SMS?",
  "configs.sms.textarea_text": "caractères restants.",
  "configs.sms.field_title": "Champs de saisie automatique",

  "configs.audit.title": "Audit",
  "configs.audit.add": "Nouvelle notification",
  "configs.audit.filter_start_date": "Date de début d'enregistrement",
  "configs.audit.filter_end_date": "Date de fin d'enregistrement",
  "configs.audit.table_register_date_hour": "DATE ET HEURE D'ENREGISTREMENT",
  "configs.audit.table_register_type": "TYPE D'ENREGISTREMENT",
  "configs.audit.table_event_date_hour": "DATE ET HEURE DE L'ÉVÉNEMENT",
  "configs.audit.table_user": "UTILISATEUR",
  "configs.audit.table_driver": "CONDUCTEUR",
  "configs.audit.form_select": "Sélectionner...",
  "configs.audit.form_input_moment": "Quand le client sera-t-il notifié?",
  "configs.audit.form_label_sms_subject": "Quel sera le sujet du SMS?",
  "configs.audit.form_label_sms_content": "Quel sera le contenu du SMS?",
  "configs.audit.textarea_text": "caractères restants.",
  "configs.audit.field_title": "Champs de saisie automatique",
  "configs.audit.sensor.output_text": "Sortie",
  "configs.audit.system_record": "Enregistrements système",
  "configs.audit.positions": "Positions",
  "configs.audit.messages": "Messages",
  "configs.audit.login": "Connexion",
  "configs.audit.electronic_fences": "Clôtures électroniques",
  "configs.audit.driver_app_settings":
    "Paramètres de l'application pour conducteurs",
  "configs.audit.commands": "Commandes",
  "configs.audit.command.expired": "Expiré",
  "configs.audit.command.received": "Reçu",
  "configs.audit.command.sent": "Envoyé",
  "configs.audit.status": "Statut",
  "configs.audit.register_date_hour": "Date et heure d'enregistrement",
  "configs.audit.event_date_hour": "Date et heure de l'événement",
  "configs.audit.datetime_execution": "Date et heure d'exécution",
  "configs.audit.expiration_datetime": "Date et heure d'expiration",
  "configs.audit.confirmation_datetime": "Date et heure de confirmation",
  "configs.audit.login_datetime": "Date et heure de connexion",
  "configs.audit.datetime_receipt": "Date et heure de réception",
  "configs.audit.datetime_reading": "Date et heure de lecture",
  "configs.audit.date_reading": "Date de lecture",
  "configs.audit.action": "Action",
  "configs.audit.origin": "Origine",
  "configs.audit.user": "Utilisateur",
  "configs.audit.driver": "Conducteur",
  "configs.audit.license_plate": "Plaque d'immatriculation du véhicule",
  "configs.audit.plate": "Plaque",
  "configs.audit.command_origin": "Origine de la commande",
  "configs.audit.settings_origin": "Origine des paramètres",
  "configs.audit.network": "Réseau",
  "configs.audit.rule": "Règle",
  "configs.audit.actuators": "Actionneurs",
  "configs.audit.sensors": "Capteurs",
  "configs.audit.electronic_fence_name": "Nom de la clôture électronique",
  "configs.audit.sender": "Expéditeur",
  "configs.audit.recipient": "Destinataire",
  "configs.audit.message": "Message",
  "configs.audit.speed": "Vitesse",
  "configs.audit.ignition": "Allumage",
  "configs.audit.battery_voltage": "Tension de la batterie",
  "configs.audit.location": "Emplacement",
  "configs.audit.temperature_sensor": "Capteur de température",
  "configs.audit.changed_field": "Champ modifié",
  "configs.audit.initial_registration": "Enregistrement initial",
  "configs.audit.final_registration": "Enregistrement final",
  "audit.options.creation": "Création",
  "audit.options.edition": "Suppression",
  "audit.options.exclusion": "Modification",
  "configs.audit.logout": "Déconnexion",
  "audit.options.settings": "Paramètres",
  "audit.options.forms": "Formulaires",
  "audit.options.alerts": "Alertes",
  "audit.options.driver_journey": "Trajet du conducteur",
  "audit.options.drivin_behavior": "Comportement de conduite",
  "audit.options.vehicles": "Véhicules",
  "audit.options.drivers": "Conducteurs",
  "audit.options.optimization": "Optimisation",
  "configs.audit.record_origin": "Origine de l'enregistrement",
  "configs.audit.record_type": "Type d'enregistrement",
  "configs.audit.license_plate_label": "Véhicule (Plaque d'immatriculation)",
  "configs.audit.create": "Créer",
  "configs.audit.update": "Mettre à jour",
  "configs.audit.delete": "Supprimer",
  "configs.audit.create_geofence": "Créer une géofence",
  "configs.audit.run_command": "Exécuter une commande sur le fleetmate",
  "configs.audit.setup_configurations": "Configurer les configurations",
  "configs.audit.remove_geofences": "Supprimer les géofences",
  "configs.audit.create_rule": "Créer une règle de sécurité",
  "configs.audit.remove_rule": "Supprimer la règle de sécurité",
  "configs.audit.fleetmate_sensors": "Capteurs du fleetmate",
  "configs.audit.remove_registers": "Supprimer les enregistrements de la file",
  "configs.menu.audit.no_records": "Il n'y a aucun enregistrement.",
  // Configs Driver App Tabs
  "configs.app.general_settings_tab": "Paramètres généraux",
  "configs.app.driver_status_tab": "État du conducteur",
  "configs.app.forms_tab": "Formulaires",
  "configs.app.journey_control_tab": "Contrôle du trajet",
  "configs.app.types_occurrence_tab": "Types d'occurrences",

  "vehicles.title": "Véhicules",
  "vehicles.button_add": "Nouveau véhicule",
  "vehicles.new.title": "Enregistrer un véhicule",
  "vehicles.edit.title": "Modifier le véhicule",
  "vehicles.table_title": "Nouveaux véhicules",
  "vehicles.table_type": "Type",
  "vehicles.table_board": "Plaque",
  "vehicles.table_created_at": "Date de création",
  "vehicles.table_price_by_km": "Coût par km",
  "vehicles.table_hardware": "Matériel",
  "vehicles.table_maximum_volume": "Volume maximum",
  "vehicles.table_maximum_weight": "Poids maximum",
  "vehicles.table_maximum_value": "Valeur maximale",
  "vehicles.table_availability": "Disponibilité",
  "vehicles.delete_many_text": "Supprimer les enregistrements sélectionnés",
  "vehicles.confirm_delete_many_title":
    "Êtes-vous sûr de vouloir supprimer les enregistrements sélectionnés?",
  "vehicles.confirm_delete_title":
    "Êtes-vous sûr de vouloir supprimer ce véhicule?",
  "vehicles.tab.hardware_title": "Matériel",
  "vehicles.tab.register_title": "Enregistrement",
  "vehicles.form.label.license_plate": "Plaque",
  "vehicles.form.label.license_plate.invalid":
    "Le numéro de plaque du véhicule doit être fourni",
  "vehicles.form.label.vehicle_type": "Type de véhicule",
  "vehicles.form.label.vehicle_type.invalid":
    "Le type de véhicule doit être renseigné",
  "vehicles.form.label.price_by_km": "Coût par km",
  "vehicles.form.label.active": "Rendre le véhicule disponible",
  "vehicles.form.label.weight": "Poids",
  "vehicles.form.label.weight.invalid":
    "La capacité de poids maximum doit être renseignée",
  "vehicles.form.label.amount": "Montant",
  "vehicles.form.label.amount.invalid":
    "La valeur maximale doit être renseignée",
  "vehicles.form.label.tank": "Réservoir",
  "vehicles.form.label.volume": "Volume",
  "vehicles.form.label.volume.invalid": "Le volume maximum doit être renseigné",
  "vehicles.form.select.material_type": "Sélectionner le type de matériau",
  "vehicles.form.label.tag": "Attributs",
  "vehicles.form.description.tag":
    "Les attributs du véhicule sont utilisés pour catégoriser les rapports et inclure des contraintes dans l'optimisation des itinéraires.",
  "vehicles.form.label.observation": "Observations",
  "vehicles.form.enter.observation": "Taper ici...",
  "vehicles.hardware.message.confirm_disable_sensor":
    "Êtes-vous sûr de vouloir désactiver ce capteur?",
  "vehicles.hardware.message.confirm_enable_sensor":
    "Êtes-vous sûr de vouloir activer ce capteur?",
  "vehicles.hardware.message.confirm_send_command":
    "Êtes-vous sûr de vouloir envoyer cette commande?",
  "vehicles.hardware.send_command_satellite.label":
    "Envoyer la commande également par satellite.",
  "vehicles.hardware.message.success_send_command":
    "La commande a été envoyée avec succès. Elle sera exécutée sous peu.",
  "vehicles.hardware.title.error_send_command":
    "Échec de l'exécution de la commande",
  "vehicles.hardware.title.error_generate_actuator_code":
    "Échec de la génération du code de l'actionneur",
  "vehicles.hardware.sensor.status_confirm.label": "Confirmation du statut",
  "vehicles.hardware.unlink_equipment": "Dissocier l'équipement",
  "vehicles.hardware.serial": "Numéro de série",
  "vehicles.hardware.firmware": "Version du micrologiciel",
  "vehicles.hardware.manufacturer": "Nom du fabricant",
  "vehicles.hardware.model": "Modèle",
  "vehicles.hardware.sensor.title": "Capteurs",
  "vehicles.hardware.actuator.title": "Actionneurs",
  "vehicles.hardware.against_password.title": "Génération de mot de passe",
  "vehicles.hardware.disable_actuator.label":
    "Sélectionnez l'actionneur que vous souhaitez désactiver",
  "vehicles.hardware.button.generate": "Générer",
  "vehicles.hardware.against_password_view":
    "Cliquez pour afficher le mot de passe",
  "vehicles.hardware.against_password_expires":
    "Ce mot de passe expirera dans : ",
  "vehicles.hardware.video_monitoring.title": "Surveillance vidéo",
  "vehicles.hardware.camera.label": "Caméra",
  "vehicles.hardware.camera.last_update": "Dernière mise à jour",
  "vehicles.hardware.camera.network_type": "Type de Réseau",
  "vehicles.hardware.no_cameras": "Ce véhicule n'a pas de caméras",
  "vehicles.hardware.network_status.title": "État du réseau",
  "vehicles.sensor.exit": "Sortie",
  "vehicles.sensor.entry": "Entrée",
  "vehicles.empty_list.message": "Vous n'avez encore enregistré aucun véhicule",
  "vehicles.hardware.copy": "Copier",
  "vehicles.hardware.copied": "Copié!",
  "vehicles.hardware.sensor.status_active.label": "Actif",
  "vehicles.hardware.sensor.status_inactive.label": "Inactif",
  "vehicles.video_monitoring": "Surveillance",
  "vehicles.video_monitoring.last_update": "Dernière mise à jour",
  "vehicles.video_monitoring.network_type": "Type de réseau",
  "vehicles.video_monitoring.hardware_information": "Informations matérielles",
  "vehicles.video_monitoring.imei": "IMEI",
  "vehicles.video_monitoring.sim_card": "Numéro de carte SIM",
  "vehicles.video_monitoring.model": "Modèle",
  vehicles_manufacturer_empty: "Aucun fabricant",
  associante_vehicle_modal_title: "Association de véhicules",
  link_vehicle: "Véhicule associé",
  unlink_vehicle: "Dissocier le véhicule",
  "trips.title": "Voyages et tâches",
  "trips.table_driver": "Chauffeur",
  "trips.table_date": "Date et heure",
  "trips.table_travelled_distance": "km parcourus",
  "trips.table_task": "Tâches",
  "trips.table_status": "Statut",
  "trips.table_driving_time": "Temps de conduite",
  "trips.table_stops": "Temps d'arrêt",
  "trips.table_alerts": "Alertes",
  "trips.table_task_details": "Voir les détails",
  "trips.table_empty_list":
    "Sélectionnez un véhicule et des dates pour afficher le rapport",

  "trips.eye.finished_routes": "Itinéraires parcourus",
  "trips.eye.planned_routes": "Itinéraires prévus",
  "trips.eye.waypoints": "Points de passage",

  "trips.pannel.average_speed": "Vit.Moyenne",
  "trips.pannel.qtd_trips": "Nbr.Voyages",
  "trips.pannel.qtd_stop": "Nbr.Arrêts",
  "trips.pannel.total_tasks": "Tâches totales",
  "trips.pannel.completed": "Terminé",
  "trips.pannel.occurrence": "Occurrences",
  "trips.pannel.partials": "Partiel",

  "trips.detail.title": "Détails de la tâche",
  "trips.detail.table_doc": "Documents",
  "trips.detail.table_status": "Statut",
  "trips.detail.table_customer": "Client",
  "trips.detail.table_voucher": "Bon de réduction",
  "trips.detail.table_occurrence": "Occurrence",
  "trips.detail.table_occurrence_date": "Date de l'événement",
  "trips.detail.table_document_number": "Numéro du document",
  "trips.detail.table_service_date": "Date de service",
  "trips.detail.table_duration": "Durée",
  "trips.detail.table_note_place": "Lieu de la note",
  "trips.detail.table_route_description": "Description de l'itinéraire",
  "trips.detail.view_on_map": "Voir sur la carte",

  "trips.task_detail.question": "Question",
  "trips.task_detail.response": "Réponse",
  "trips.task_detail.customer": "Client",
  "trips.task_detail.doc": "Document",
  "trips.task_detail.status": "Statut",
  "trips.task_detail.address": "Adresse",
  "trips.task_detail.phone": "Téléphone",
  "trips.task_detail.weight": "Poids",
  "trips.task_detail.volume": "Volume",
  "trips.task_detail.service_window": "Plage de service (heures effectives)",
  "trips.task_detail.expected_duration": "Durée prévue (durée effective)",
  "trips.task_detail.value": "Valeur",
  "trips.task_detail.attributes": "Attributs",

  "sensors.title": "Capteurs",
  "sensors.table_date": "Date",
  "sensors.table_hour": "Heure",
  "sensors.table_sensor": "Capteur",
  "sensors.table_type": "Type",
  "sensors.table_last_read": "Dernière lecture",
  "sensors.table_vehicle": "Véhicule",
  "sensors.table_localization": "Localisation",
  "sensors.table_driver": "Chauffeur",
  "sensors.table_alert": "Alerte",

  "sensor_detail.table_view_route": "Voir l'itinéraire",
  "sensor_detail.table_view_map": "Voir sur la carte",

  "journey.title": "Parcours des chauffeurs",
  "journey.drivers_title": "Chauffeurs",
  "journey.table_activity": "ACTIVITÉ",
  "journey.table_duration": "DURÉE ATV.",
  "journey.table_start": "DÉBUT",
  "journey.table_end": "FIN",
  "journey.table_location": "EMPLACEMENT",
  "journey.table_overflow": "DÉPASSEMENT",
  "journey.table_extra": "HORAIRES ORDINAIRES",
  "journey.table_alerts": "ALERTE",
  "journey.driver_name": "CONDUCTEUR",
  "journey.vehicle_license_plate": "VÉHICULE",
  "journey.cards.journey": "Parcours",
  "journey.cards.driving": "Conduite",
  "journey.cards.rest": "Repos",
  "journey.cards.wait": "Attente",
  "journey.cards.lunch": "Déjeuner",
  "journey.cards.extra_hour": "Heure supplémentaire",
  "journey.cards.ordinary_extra_hour": "Heures ordinaires",
  "journey.add_register": "Ajouter un enregistrement manuellement",
  "journey.we_found": "Nous avons trouvé",
  "journey.registers": "enregistrements",
  "journey.journey_reports": "Rapports de parcours",
  "journey.time_sheet_type": "Type de rapport de feuille de temps",
  "journey.consolidated": "Consolidé",
  "journey.detailed": "Détaillé",
  "journey.time_sheet": "Feuille de temps",
  "journey.archive_afd_afdt": "Archiver AFD / AFDT",
  "journey.edit_records": "Modifier les enregistrements",
  "journey.selected_driver": "Chauffeur sélectionné",
  "journey.all": "Tous",
  "journey.form_title_add": "Ajouter un enregistrement",
  "journey.form_title_edit": "Modifier l'enregistrement",
  "journey.form_title_delete": "Supprimer l'enregistrement",
  "journey.form_vehicle_label": "Véhicule",
  "journey.form_active_type": "Type d'activité",
  "journey.form_date_initial": "Début",
  "journey.form_date_final": "Fin",
  "journey.form_reason_label_add": "Raison de l'ajout.",
  "journey.form_reason_label_edit": " Raison de la modification.",
  "journey.form_reason_label_delete": "Raison de la suppression.",
  "journey.form_reason_enter": "Décrivez ici...",
  "journey.form_reason_characters": "caractères restants.",

  "calendar.january": "Janvier",
  "calendar.february": "Février",
  "calendar.march": "Mars",
  "calendar.april": "Avril",
  "calendar.may": "Peut",
  "calendar.june": "Juin",
  "calendar.july": "Juillet",
  "calendar.august": "Août",
  "calendar.september": "Septembre",
  "calendar.october": "octobre",
  "calendar.november": "Novembre",
  "calendar.december": "Décembre",
  "calendar.sunday": "Dimanche",
  "calendar.monday": "Lundi",
  "calendar.tuesday": "Mardi",
  "calendar.wednesday": "Mercredi",
  "calendar.thursday": "Jeudi",
  "calendar.friday": "Vendredi",
  "calendar.saturday": "Samedi",

  "configs.fleetmate.settings": "Paramètres",
  "configs.fleetmate.embedded_intelligence": "Intelligence embarquée",
  "configs.fleetmate.ei.add_button": "Ajouter un jeu de règles",
  "configs.fleetmate.ei.add_button_new_rule": "Ajouter une règle",
  "configs.fleetmate.ei.add_button_new_setup": "Nouvelle configuration",
  "configs.fleetmate.ei.rule_name": "Nom de la règle",
  "configs.fleetmate.ei.created_at": "Date de création",
  "configs.fleetmate.ei.updated_at": "Dernière édition",
  "configs.fleetmate.ei.user": "Utilisateur",
  "configs.fleetmate.ei.qtd_events": "Nombre d'événements",
  "configs.fleetmate.ei.eligibility": "Activation",
  "configs.fleetmate.ei.action_vehicle_association": "Association de véhicules",
  "configs.fleetmate.ei.label_from_data": "Date de début",
  "configs.fleetmate.ei.label_to_data": "Date de fin",
  "configs.fleetmate.ei.confirm_modal_title":
    "Êtes-vous sûr de vouloir supprimer cet enregistrement",
  "configs.fleetmate.ei.confirm_modal_message":
    "Cette action ne peut pas être annulée",
  "configs.fleetmate.ei.rule_name_placeholder": "Tapez un nom ici...",
  "configs.fleetmate.ei.create_rule_using": "Créer une règle en utilisant:",
  "configs.fleetmate.ei.status_sensors": "État des capteurs",
  "configs.fleetmate.ei.status_driver": "État du chauffeur",
  "configs.fleetmate.ei.electronic_fences": "Clôtures électroniques",
  "configs.fleetmate.ei.sensors": "Capteur",
  "configs.fleetmate.ei.sensors_description":
    "Quels capteurs déclencheront la règle?",
  "configs.fleetmate.ei.status_sensor": "État du capteur",
  "configs.fleetmate.ei.status_sensor_description":
    "Quel état déclenchera la règle?",
  "configs.fleetmate.ei.status_driver_description":
    "Quel état du chauffeur déclenchera la règle?",
  "configs.fleetmate.ei.group_electronic_fences":
    "Déclenchement électronique du guide",
  "configs.fleetmate.ei.group_electronic_fences_description":
    "Quels groupes de clôtures électroniques déclencheront la règle?",
  "configs.fleetmate.ei.length_of_stay": "Durée du séjour",
  "configs.fleetmate.ei.electronic_fences_when": "Quand?",
  "configs.fleetmate.ei.electronic_fences_when_description":
    "Quand la règle sera-t-elle activée?",
  "configs.fleetmate.ei.when_the_rule_will_be_deactivated":
    "Date de désactivation de la règle",
  "configs.fleetmate.ei.trigger_by_time_pt1":
    "Déclencher la règle si le chauffeur ne signale pas d'état après",
  "configs.fleetmate.ei.trigger_by_time_pt2": "minutes d'arrêt.",
  "configs.fleetmate.ei.actuators_label": "Actionneurs",
  "configs.fleetmate.ei.actuators_description":
    "(Sélectionnez les actions que la règle effectuera)",
  "configs.fleetmate.ei.fuel_blockage": "Blocage de carburant",
  "configs.fleetmate.ei.chest_blockage": "Verrouillage du coffre principal",
  "configs.fleetmate.ei.fifth_wheel_blockage":
    "Verrouillage de la cinquième roue",
  "configs.fleetmate.ei.siren": "Sirène",
  "configs.fleetmate.ei.side_chest_blockage": "Verrouillage du coffre latéral",
  "configs.fleetmate.ei.wait_pt1": "Attendre",
  "configs.fleetmate.ei.wait_pt2":
    "secondes en condition pour déclencher la règle",
  "configs.fleetmate.ei.generate_alert_monitoring":
    "Générer une alerte sur la surveillance",
  "configs.fleetmate.ei.disable_jammer_detection":
    "Désactiver la détection de brouilleur",
  "configs.fleetmate.ei.tags_label": "Tags",
  "configs.fleetmate.ei.tags_description":
    "(Utiliser pour appliquer la règle uniquement aux véhicules associés aux tags)",
  "configs.fleetmate.setting.interval_lorawan":
    "Portée de communication dans le réseau LoRaWAN",
  "configs.fleetmate.setting.interval_iridium":
    "Intervalle de communication dans le réseau Iridium",
  "configs.fleetmate.setting.ignition_on": "Allumage",
  "configs.fleetmate.setting.ignition_off": "Éteindre l'allumage",
  "configs.fleetmate.setting.entries": "Entrées",
  "configs.fleetmate.setting.entrie": "Entrée",
  "configs.fleetmate.setting.outputs": "Sorties",
  "configs.fleetmate.setting.output": "Sortie",
  "configs.fleetmate.setting.driver_controls_exit":
    "Permettre au conducteur de contrôler la sortie",
  "configs.fleetmate.setting.type": "Taper",
  "configs.fleetmate.setting.type_encoded": "Sortie codée",
  "configs.fleetmate.setting.type_decoded": "Non codifié",
  "configs.fleetmate.setting.tolerance_time": "Temps de tolérance",
  "configs.fleetmate.setting.tolerance_for_rule_execution":
    "Tolérance pour l'exécution des règles",
  "configs.fleetmate.setting.seconds": "secondes",
  "traking.center": "Centre",
  "configs.fleetmate.ei.vehicle_type": "Type de véhicule",
  "configs.fleetmate.ei.attributes_tag": "Attributs (TAG)",
  "configs.fleetmate.ei.vehicle_association": "Association de véhicules",
  "configs.fleetmate.ei.vehicle": "Véhicule",
  "configs.fleetmate.ei.vehicle_select": "Sélectionnez un véhicule...",
  "configs.fleetmate.ei.configs.fleetmate.ei.rule_drive_move":
    "Déclencher la règle si le conducteur déplace le véhicule de plus de",
  "configs.fleetmate.ei.configs.error.new_driver_status":
    "Il est nécessaire de renseigner le statut du Chauffeur",
  "configs.fleetmate.ei.change_driver_status_to":
    "Changer le statut du conducteur en : ",
  "configs.fleetmate.ei.warning_the_driver": "Alerter le conducteur",
  "configs.fleetmate.ei.generate_alert_in_monitoring":
    "Générer une alerte dans la surveillance",
  "configs.fleetmate.ei.sending_alerts_satellite":
    "Envoi d'alertes via le réseau satellitaire",

  "fleetmate.time.meters": "mètres",
  "fleetmate.time.minute": "Minute",
  "fleetmate.time.minutes": "Minutes",
  "fleetmate.entries.fifth_wheel": "Cinquième roue",
  "fleetmate.entries.siren": "Sirène",
  "fleetmate.entries.fuel_blockage": "Blocage de carburant",
  "fleetmate.entries.trunk_lock": "Verrouillage du coffre",
  "fleetmate.entries.side_door": "Porte latérale",
  "fleetmate.entries.flush_door": "Porte principale",
  "fleetmate.entries.right_door": "Porte droite",
  "fleetmate.entries.left_door": "Porte gauche",
  "fleetmate.entries.panel": "Panneau",
  "fleetmate.entries.coupling": "Accouplement",
  "fleetmate.entries.trailer_door": "Porte de remorque",
  "fleetmate.entries.panic_button": "Panic",
  "fleetmate.entries.ignition": "Allumage",
  "fleetmate.entries.silo": "Silo",
  "fleetmate.entries.flush_door_main": "Porte principale du coffre",
  "fleetmate.entries.panel_narrow": "Écran de fenêtre",
  "fleetmate.outings.fuel_block": "Blocage de carburant",
  "fleetmate.outings.side_door_lock": "Verrouillage du coffre latéral",
  "fleetmate.outings.flush_door_lock": "Verrouillage du coffre principal",
  "fleetmate.outings.fifth_wheel_lock": "Verrouillage de la cinquième roue",
  "fleetmate.outings.sirem_alarm_lock": "Alarme",
  "fleetmate.outings.trailer_door_lock": "Verrouillage de la remorque",
  "fleetmate.outings.ignition_lock": "Serrure d'allumage",
  "fleetmate.sensor.side_chest": "Coffre latéral",
  "fleetmate.sensor.main_chest": "Coffre principal",
  "fleetmate.sensor.jammer_detection": "Détection de brouilleur",
  "fleetmate.sensor.hitch": "Attelage",
  "fleetmate.sensor.panic": "Panic",
  "fleetmate.sensor.window_screen": "Écran de fenêtre",
  "fleetmate.sensor.actived": "Activé",
  "fleetmate.sensor.disabled": "Désactivé",
  "fleetmate.sensor.right_door": "Porte droite",
  "fleetmate.sensor.left_door": "Porte gauche",
  "fleetmate.efg.operation_base": "Base d'opérations",
  "fleetmate.efg.client": "Client",
  "fleetmate.efg.authorized_stopping_point": "Point d'arrêt autorisé",
  "fleetmate.efg.authorized_route": "Itinéraire autorisé",
  "fleetmate.efg.system_routes":
    "Itinéraires du système (optimisation des itinéraires)",
  "fleetmate.efg.jammer_interface_zone": "Zone d'interférence de brouilleur",
  "fleetmate.efg.risk_zone": "Zone à risque",

  "fleetmate.actuators.level_block": "Niveau de verrouillage",

  "fleetmate.geofence_base": "Base",
  "fleetmate.geofence_client": "Client",
  "fleetmate.geofence_risk_area": "Zone de risque",
  "fleetmate.geofence_autorized": "Point d'arrêt autorisé",

  "actuators.fuel_blockage": "Blocage de carburant",
  "actuators.lock_main_chest": "Verrouillage du coffre principal",
  "actuators.fifth_wheel_lock": "Verrouillage de la cinquième roue",
  "actuators.lock_side_chest": "Verrouillage du coffre latéral",
  "actuators.siren": "Sirène",

  "sensor.side_chest": "Coffre latéral",
  "sensor.fifth_wheel": "Cinquième roue",
  "sensor.main_chest": "Coffre principal",
  "sensor.right_door": "Porte droite",
  "sensor.panel": "Panneau",
  "sensor.panic": "Panic",
  "sensor.ignition": "Allumage",
  "sensor.hitch": "Attelage",

  "component.input_tag_new.placeholder": "Ajouter un nouveau tag : ",
  "component.input_tag.placeholder": "Ajouter un tag",
  "component.input_tag.emptylabel": "Aucun résultat à afficher.",

  "configs.user.access_profile.title": "Modifier les niveaux d'accès",
  "configs.user.access_profile.checkbox_all": "Tout sélectionner",
  "configs.user.access_profile.message.success":
    "Niveaux d'accès modifiés avec succès.",
  "message.failed.start.route":
    "Échec du démarrage de la rotérisation, fournissez plus de détails et continuez",
  "message.success.save_data": "Données enregistrées avec succès !",
  "message.error.save_data": "Erreur lors de l'enregistrement des données !",
  "message.error.ownuser": "Impossible de modifier vos propres données !",
  "message.error.save_data.existplate":
    "Cette plaque est déjà enregistrée ou le format de la plaque est incorrect.",
  "message.success.delete_data": "Enregistrement supprimé avec succès !",
  "message.error.delete_data":
    "Erreur lors de la suppression de l'enregistrement !",
  "message.success.delete_many_data": "Enregistrements supprimés avec succès !",
  "message.error.delete_many_data":
    "Erreur lors de la suppression des enregistrements !",
  "message.action_not_reversed": "Cette action ne peut pas être inversée",
  "message.success.load_data": "Données chargées avec succès.",
  "message.error.load_data": "Échec du chargement des données !",

  "message.user_delete": "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
  "message.user_reset_password":
    "Êtes-vous sûr de vouloir réinitialiser le mot de passe de l'utilisateur ?",
  "message.user_successfully_reset_password":
    "Réinitialisation du mot de passe réussie !",
  "message.user_error_reset_password":
    "Erreur lors de la réinitialisation du mot de passe !",
  "message.user_successfully_reset_password_send_email":
    "L'utilisateur recevra un lien dans son e-mail enregistré pour créer un nouveau mot de passe.",
  "message.user_successfully_deleted": "Utilisateur supprimé avec succès !",
  "message.user_successfully_guest": "Utilisateur invité avec succès !",
  "message.user_receive_link":
    "L'utilisateur recevra un lien dans son e-mail enregistré.",
  "message.error_other_email": "Entrez un autre e-mail",
  "message.error_invalid_name": "Nom invalide",
  "message.error.date":
    "La date de fin doit être supérieure à la date de début",
  "fence_group.client": "Client",
  "fence_group.operation_base": "Base d'opérations",
  "fence_group.risk_zone": "Zone à risque",
  "fence_group.authorized_route": "Point de passage autorisé",
  "fence_group.authorized_stopping_point": "Itinéraire autorisé",

  "weekday.sunday": "Dimanche",
  "weekday.monday": "Lundi",
  "weekday.tuesday": "Mardi",
  "weekday.wednesday": "Mercredi",
  "weekday.thursday": "Jeudi",
  "weekday.friday": "Vendredi",
  "weekday.saturday": "Samedi",

  "electronic_fences.title": "Clôtures électroniques",
  "electronic_fences.new.title": "Nouvelle clôture électronique",
  "electronic_fences.edit.title": "Modifier la clôture électronique",
  "electronic_fences.button_add": "Nouvelle clôture électronique",
  "electronic_fences.table_name": "Nom",
  "electronic_fences.table_type": "Type",
  "electronic_fences.table_group": "Groupe",
  "electronic_fences.table_created_at": "Date de création",
  "electronic_fences.table_qtd_alert": "Nombre d'alertes",
  "electronic_fences.table_author": "Auteur",
  "electronic_fences.table_enable": "Activer",
  "electronic_fences.initial_data": "Date de début",
  "electronic_fences.final_data": "Date de fin",
  "electronic_fences.action_associate_vhicles": "Associer des véhicules",
  "electronic_fences.action_retroact": "Rétroactif",
  "electronic_fences.action_edit": "Modifier la clôture électronique",
  "electronic_fences.action_delete": "Supprimer la clôture électronique",
  "electronic_fences.label_electronic_fence_name":
    "Nom de la clôture électronique",
  "electronic_fences.enter_electronic_fence_name": "Saisissez le nom ici...",
  "electronic_fences.label_fences_group": "Groupe de clôtures",
  "electronic_fences.select_fences_group": "Choisissez un groupe...",
  "electronic_fences.title_electronic_fence_alerts":
    "Alertes de clôtures électroniques",
  "electronic_fences.label_alert_when": "Alerte lorsque:",
  "electronic_fences.title_hours": "Heures",
  "electronic_fences.label_period_from": "De",
  "electronic_fences.label_period_to": "Jusqu'à",
  "electronic_fences.label_enter_fence": "Entrée dans la clôture",
  "electronic_fences.label_exit_fence": "Sortie à la clôture",
  "electronic_fences.label_stay_longer": "Rester plus longtemps que",
  "electronic_fences.label_report": "Rapport",
  "electronic_fences.label_fence_minutes": "minutes de clôtures",
  "electronic_fences.label_remain_less": "Rester moins de",
  "electronic_fences.label_fence_km": "km/h sur la clôture.",
  "electronic_fences.label_speed_higher": "La vitesse est supérieure à",
  "electronic_fences.label_email_notify": "Notifier par e-mail",
  "electronic_fences.label_notify_monitoring":
    "Notifier lors de la surveillance",
  "electronic_fences.label_email_address": "Adresse e-mail",
  "electronic_fences.label_email_address_detail":
    "• Séparées par des virgules • Jusqu'à 5",
  "electronic_fences.label_tags":
    "Attributs (Générer des alertes uniquement pour les véhicules avec les attributs suivants)",
  "electronic_fences.map_option_circle": "Cercle",
  "electronic_fences.map_option_polygonal": "Polygone",
  "electronic_fences.map_option_route": "Itinéraire",
  "electronic_fences.map_import_route": "Importer un itinéraire",
  "electronic_fences.map_import_stop_point": "Importer des points d'arrêt",
  "electronic_fences.map_new_circle": "Créer une clôture circulaire",
  "electronic_fences.map_new_polygonal": "Créer une clôture polygonale",
  "electronic_fences.map_new_route": "Créer une clôture de route",
  "electronic_fences.map_info_import_route":
    "Utilisez cette option pour importer toutes les références de l'itinéraire utilisé. Cette option est recommandée lorsque vous ne souhaitez pas utiliser l'itinéraire calculé par le système.",
  "electronic_fences.map_info_import_stop_point":
    "Utilisez cette option pour importer uniquement les références des points de passage sur l'itinéraire. Dans cette option, l'itinéraire sera calculé automatiquement par le système.",
  "electronic_fences.map_label_address": "Adresse",
  "electronic_fences.map_label_radius": "Rayon (Km)",
  "electronic_fences.map_enter_address": "Entrez l'adresse ici...",
  "electronic_fences.map_import_references": "Importer des références",
  "electronic_fences.map_download_template": "Télécharger le modèle",
  "electronic_fences.map_add_route": "Ajouter une destination",
  "electronic_fences.map_label_perimeter": "Périmètre (Km)",
  "electronic_fences.map_maintain_association_from":
    "Maintenir l'association à partir de",
  "electronic_fences.map_maintain_association_to": "jusqu'à",
  "electronic_fences.map_label_membership_indefinitely":
    "Garder l'adhésion indéfiniment.",
  "electronic_fences.select_author": "Sélectionnez l'auteur...",
  "electronic_fences.select_type": "Sélectionnez le type...",
  "electronic_fences.show_enabled_fences":
    "Afficher uniquement les clôtures activées",
  "electronic_fences.confirm_delete_title":
    "Êtes-vous sûr de vouloir supprimer cette clôture électronique ?",

  "traking.customize_grid": "Personnaliser la grille",
  "traking.option_path": "Itinéraire",
  "traking.option_vehicle": "Véhicules",
  "traking.option_customer": "Clients",
  "traking.option_electronic_fence": "Clôture électronique",
  "traking.filter_vehicle_type": "Type de véhicule",
  "traking.filter_vehicle": "Véhicule",
  "traking.filter_driver": "Chauffeur",
  "traking.filter_driver_status": "Statut du chauffeur",
  "traking.filter_route_description": "Description de l'itinéraire",
  "traking.filter_geofence": "Geofence",
  "traking.filter_select_label": "Sélectionner un filtre",
  "traking.filter_initial_date": "Début",
  "traking.filter_final_date": "Fin",
  "traking.filter_add_new": "Nouveau filtre",
  "traking.customize_grid_subtitle":
    "Sélectionnez et organisez les éléments qui apparaîtront dans la grille.",
  "traking.table_route_description": "Descriptif de l'itinéraire",
  "traking.table_timeline_tasks": "Chronologie des tâches",
  "traking.table_route": "Itinéraire",
  "traking.table_plate": "Plaque",
  "traking.table_status": "Statut",
  "traking.table_battery": "Batterie",
  "traking.table_left_door": "Porte du conducteur",
  "traking.table_right_door": "Porte de l'auto-stop",
  "traking.table_fifth_wheel": "Cinquième roue",
  "traking.table_side_door": "Porte latérale du coffre",
  "traking.table_flush_door": "Porte arrière du coffre",
  "traking.table_panel": "Panneau",
  "traking.table_panel_narrow": "Écran de la fenêtre",
  "traking.table_coupling": "Accouplement",
  "traking.table_trailer_door": "Porte de la remorque",
  "traking.table_panic_button": "Bouton de panique",
  "traking.table_ignition": "Allumage",
  "traking.table_silo": "Silo",
  "traking.table_fuel_block": "Bloc de carburant",
  "traking.table_side_door_lock": "Verrou du coffre latéral",
  "traking.table_flush_door_lock": "Verrou du coffre arrière",
  "traking.table_fifth_wheel_lock": "Verrou de la cinquième roue",
  "traking.table_sirem_alarm_lock": "Alarme",
  "traking.table_trailer_door_lock": "Verrou de la remorque",
  "traking.table_flush_door_main": "Porte principale du coffre",
  "traking.table_macaddress": "Adresse Mac",
  "traking.table_location": "Emplacement",
  "traking.table_driving_time": "Temps de conduite",
  "traking.table_time_stoped": "Temps d'arrêt",
  "traking.table_idle_engine_time": "Temps de ralenti du moteur",
  "traking.table_address": "Adresse",
  "traking.table_speed": "Vitesse",
  "traking.table_options": "Choix",
  "traking.table_geofence": "Geofence",
  "traking.btn_hidden": "Cacher",
  "traking.msg_box": "Boîte de message",
  "traking.notification_center": "Centre de notification",
  "traking.btn_all_read": "Tout marquer comme lu",
  "traking.btn_add_comment": "Ajouter un commentaire",
  "traking.btn_mark_read": "Marquer comme lu",
  "traking.label_comment": "Commentaire",
  "traking.enter_message": "Message...",
  "traking.task_detail_customer": "Client",
  "traking.task_detail_phone": "Téléphone",
  "traking.task_service_window": "Fenêtre de service",
  "traking.task_service_time": "Temps de service",
  "traking.table_document": "Document",
  "traking.table_image": "Image",
  "traking.task_eta_provided": "Planifié",
  "traking.task_eta_accomplished": "Réalisé",
  "traking.task_previous": "Précédent",
  "traking.task_next": "Suivant",
  "traking.status_pending": "En attente",
  "traking.status_completed": "Terminé",
  "traking.status_partial": "Partiel",
  "traking.attached_image": "Image jointe",
  "traking.attached_doc": "Document joint",
  "traking.task_detail_vehicle": "Véhicule",
  "traking.task_detail_driver": "Chauffeur",
  "traking.task_detail_cell_phone": "Téléphone portable",
  "traking.task_detail_route_id": "ID de l'itinéraire",
  "traking.task_detail_total_value": "Valeur totale",
  "traking.task_detail_volume_total": "Volume total",
  "traking.task_detail_weight_total": "Poids total",
  "traking.task_detail_journey": "Voyage",
  "traking.task_detail_total_route_km": "Kilométrage total de l'itinéraire",
  "traking.task_detail_start_of_route": "Début de l'itinéraire",
  "traking.task_detail_end_of_route": "Fin de l'itinéraire",
  "traking.task_detail_fuel_consumption": "Consommation de carburant/km",
  "traking.task_detail_fuel_total": "Consommation totale de carburant",
  "traking.task_detail_total_toll": "Péages totaux",
  "traking.task_network_status": "État du réseau",
  "traking.task_actuators": "Actionneurs",
  "traking.task_sensors": "Capteurs",
  "traking.notify_review": "Revoir",
  "traking.notify_comment": "Commentaire",
  "traking.footer_number_routes": "Nombre d'itinéraires",
  "traking.footer_completed_tasks": "Tâches terminées",
  "traking.footer_occurrences": "Occurrences",
  "traking.footer_delays": "Retards",
  "traking.footer_average_service_time": "Temps de service moyen",
  "traking.footer_average_speed": "Vitesse moyenne",
  "traking.task_type_label": "Type de tâche",
  "traking.task_review_observation_label": "Remarques",
  "traking.task_review_documents_label": "Documents",
  "traking.task_review_driver_label": "Chauffeur",
  "traking.task_review_allocated_vehicle_label": "Véhicule attribué",
  "traking.task_review_weight_label": "Poids",
  "traking.task_review_volume_label": "Volume",
  "traking.task_review_value_label": "Valeur",
  "traking.task_review_exit_point_label": "Distance du point de sortie",
  "traking.task_review_task_location_label":
    "Distance de l'emplacement de la tâche",
  "traking.task_review_title": "Revoir la tâche",
  "traking.task_comment_title": "Ajouter un commentaire",
  "traking.task_comment": "Commentaire",
  "traking.task_task_type": "Type de tâche",
  "traking.table_km": "Km",
  "traking.table_total_weight": "Poids",
  "traking.table_total_volume": "Volume",
  "traking.table_total_amount": "Valeur",
  "traking.table_last_date_comunication_vehicle":
    "Dernière communication du véhicule",
  "traking.table_last_date_update_gps_vehicle":
    "Dernière mise à jour GPS du véhicule",
  "traking.task.modal_locked": "Fermé",
  "traking.task.modal_open": "Ouvert",
  "traking.task.modal_blocked": "Bloqué",
  "traking.task.modal_unblocked": "Débloqué",
  "traking.task.modal_fifth_wheel_blocked": "Engagé",
  "traking.task.modal_fifth_wheel_unblocked": "Désengagé",
  "traking.task.modal_off": "Éteint",
  "traking.task.modal_on": "Allumé",
  "traking.task.modal_disabled": "Désactivé",
  "traking.task.modal_enabled": "Activé",
  "traking.task.modal_lock": "Fermer",
  "traking.task.modal_unlock": "Ouvrir",
  "traking.task.modal_block": "Bloquer",
  "traking.task.modal_unblock": "Débloquer",
  "traking.task.modal_turnoff": "Éteindre",
  "traking.task.modal_turnon": "Allumer",
  "traking.task.modal_disable": "Désactiver",
  "traking.task.modal_enable": "Activer",
  "traking.task.modal_sensors_entry": "Capteurs (Entrées)",
  "traking.task.modal_actuators_exit": "Actionneurs (Sorties)",
  "traking.task.modal_executing": "Commande en cours d'exécution",
  "traking.task.modal_current_status": "Statut Actuel",
  "traking.task.modal_executed": "Commande Exécutée",
  "traking.task.modal_network_error":
    "Tous les réseaux de communication sont hors ligne.",
  "traking.task.modal_attendance_window": "à",
  "traking.task.modal_arrival_time": "Heure d'arrivée",
  "traking.task.modal_phone_number": "Numéro de téléphone",
  "traking.notifications.modal_today": "Aujourd'hui",
  "traking.notifications.modal_yesterday": "Hier",

  "traking.routing.error_document_duplicated":
    "Le numéro du document existe déjà",
  "traking.routing.error_document_invalid":
    "Le numéro du document n'est pas valide",
  "traking.routing.error_task_type_invalid":
    "Le type de tâche n'est pas valide",
  "traking.routing.error_vehicle_invalid": "Données du véhicule invalides",
  "traking.routing.error_cod_customer_invalid": "Code client invalide",
  "traking.routing.error_customer_invalid": "Nom de client invalide",
  "traking.routing.error_address_invalid":
    "L'adresse est incorrecte. Veuillez vérifier s'il est correct et/ou existe.",
  "traking.routing.error_weight_invalid": "Poids invalide",
  "traking.routing.error_volume_invalid": "Volume invalide",
  "traking.routing.error_amount_invalid": "Valeur invalide",
  "traking.routing.error_input_invalid_details":
    "Toute valeur d'entrée est vide, incorrecte ou invalide",
  "traking.routing.error_failed_routerization": "Échec du routage",
  "traking.routing.error_failed_routerization_details":
    "Veuillez vérifier l'adresse et les informations client et vous assurer qu'elles sont valides",

  "driving_behavior.title": "Comportement de conduite",
  "driving_behavior.subtitle": "Chauffeurs",
  "driving_behavior.table_ranking": "Classement",
  "driving_behavior.table_driver": "Chauffeur",
  "driving_behavior.table_km_traveled": "Km parcourus",
  "driving_behavior.table_driving_hours": "Heures de conduite",
  "driving_behavior.table_acceleration": "Accélération",
  "driving_behavior.table_braking": "Freinage",
  "driving_behavior.table_cell_phone": "Utilisation du téléphone portable",
  "driving_behavior.table_search_underwear": "Recherche de virage",
  "driving_behavior.table_speeding": "Excès de vitesse",
  "driving_behavior.filter_driver_enter": "Sélectionnez le chauffeur...",
  "driving_behavior.filter_ranking_label_between": "Entre",
  "driving_behavior.filter_ranking_label_and": "et",
  "driving_behavior.detail_underwear": "Virage",
  "driving_behavior.detail_speed": "Vitesse",
  "driving_behavior.detail_driving_time": "Temps de conduite",
  "driving_behavior.detail_average_text": "Moyenne générale",

  "sensors.table_datetime": "Date et heure",
  "sensors.table_location": "Emplacement",
  "sensors.alert_rules_button_text": "Règles d'alerte des capteurs",
  "sensors.view_location": "Voir l'emplacement",
  "sensors.option_edit_rule": "Modifier la règle",
  "sensors.option_delete_rule": "Supprimer la règle",
  "sensors.new_alert_rule_sensor":
    "Créer une nouvelle règle d'alerte de capteur",
  "sensors.rule.table_created_at": "Date de création",
  "sensors.rule.table_rule_name": "Nom de la règle",
  "sensors.rule.table_ruleset_name": "Nom du jeu de règles",
  "sensors.rule.table_sensor_type": "Type de capteur",
  "sensors.rule.table_user": "Utilisateur responsable",
  "sensor_detail.table_date_time": "Date et heure",
  "sensor_detail.table_vehicle": "Véhicule",
  "sensor_detail.table_read": "Lecture",
  "sensor_detail.table_battery": "Batterie",
  "sensor_detail.table_route": "Itinéraire",
  "sensor_detail.table_location": "Emplacement",
  "sensor_detail.table_driver": "Chauffeur",
  "sensor_detail.table_alert": "Alertes",
  "sensors.sonsor_id": "ID du capteur",
  "sensors.chart_label": "Niveau d'alerte",
  "sensors.temperature": "Température",
  "sensors.speed": "Vitesse",
  "sensors.fuel": "Carburant",
  "sensors.battery": "Batterie",
  "sensors.CO2": "CO₂",
  "sensors.camera": "Caméra",
  "sensors.moisture": "Humidité",
  "sensors.last_update": "Dernière mise à jour:",
  "sensors.filter_last_read_label": "Dernière lecture:",
  "sensors.filter_start_date": "Début",
  "sensors.filter_end_date": "Fin",
  "sensors.filter_sensor_type": "Type de capteur",
  "sensors.form_alert_name": "Nom de l'alerte",
  "sensors.form_alert_name_enter": "Entrez le nom de l'alerte ici",
  "sensors.form_type": "Type",
  "sensors.form_send_alert_label": "Envoyer une alerte lorsque:",
  "sensors.form_option_rule_more_then": "Plus grand que",
  "sensors.form_option_rule_less_then": "Moins grand que",
  "sensors.form_option_rule_percent": "Changement en pourcentage",
  "sensors.form_rule_enter": "Entrez ici...",
  "sensors.form_option_operator_and": "et",
  "sensors.form_option_operator_or": "ou",
  "sensors.form_add_condition": "Ajouter une condition",
  "sensors.form_change_on": "Alerte lorsque:",
  "sensors.form_receive_event": "À la réception de l'événement",
  "sensors.form_weekdays_label": "Jours de la semaine",
  "sensors.form_issue_alert_when_label": "Émettre une alerte lorsque:",
  "sensors.form_from": "De:",
  "sensors.form_to": "À:",
  "sensors.form_and": "et",
  "sensors.form_notify_email": "Notifier par e-mail",
  "sensors.form_notify_traking": "Notifier lors du suivi",
  "sensors.form_notify_sms": "Notifier par SMS",
  "sensors.form_alert_more_then_enter": "Informations",
  "sensors.form_alert_more_then":
    "Alerte uniquement lorsque le temps restant est supérieur à",
  "sensors.form_condition_seconds": "secondes en condition.",
  "sensors.form_inhibit_alerts_within_references":
    "Inhiber les alertes dans les références de base enregistrées.",
  "sensors.form_inhibit_alerts_within_references_customer":
    "Inhiber les alertes dans les références des clients.",
  "sensors.form_inhibit_alerts_vehicles_not_associate":
    "Inhiber les alertes lorsque le véhicule n'a pas de tâche associée.",
  "sensors.form_title": "Créer une alerte de capteur",
  "sensors.status_enabled": "Activé",
  "sensors.status_disabled": "Désactivé",
  "sensors.value_open": "Ouvert",
  "sensors.value_close": "Fermé",
  "sensors.unlinked_vehicle": "Véhicule dissocié avec succès",
  "sensors.linked_vehicle": "Véhicule associé avec succès",
  "configs.app.general_settings.updated": "Paramètres mis à jour avec succès",
  "configs.app.block_vehicle_ignition":
    "Bloquer le contact du véhicule au début du trajet",
  "configs.app.not_allow_driver_disable_voice":
    "Ne laissez pas le conducteur désactiver les notifications vocales",

  "alerts.alert_title": "Alertes",
  "alerts.description": "Description",
  "alerts.table_event_date": "Date de l'événement",
  "alerts.table_alert": "Alerte",
  "alerts.table_type_alert": "Type d'alerte",
  "alerts.table_reading": "Lecture",
  "alerts.table_vehicle": "Véhicule",
  "alerts.table_route": "Itinéraire",
  "alerts.table_location": "Emplacement",
  "alerts.table_driver": "Chauffeur",
  "alerts.filter_initial_date_label": "Date de début",
  "alerts.filter_end_date_label": "Date de fin",
  "alerts.option_add_comment": "Ajouter un commentaire",
  "alerts.option_view_comments": "Voir les commentaires",
  "alerts.view_comments_title": "Voir les commentaires",
  "alerts.video_image": "Image",
  "alerts.video_label": "Vidéo",
  "alerts.comments_not_found": "Aucun avis trouvé !",
  "alerts.filter_vehicle_type_label": "Type de véhicule",
  "alerts.filter_alert_type_label": "Type d'alerte",
  "alerts.filter_vehicle_label": "Véhicule",
  "alerts.confirm_delete_title":
    "Êtes-vous sûr de vouloir supprimer cette alerte ?",

  "electronic_fences.upload_file":
    "Envoyer le fichier de configuration des clôtures",
  "electronic_fences.select_file":
    "Sélectionner le fichier de configuration des clôtures",

  "configs.group.name_input": "Entrez un nom pour décrire la configuration",
  "jorney.no_end_jorney": "Pas de fin de voyage",
};
